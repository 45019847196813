import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
function getPointCategoryName(point, dimension) {
  var series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"]];
}

const dataSet = [
  {
    name: "Jan",
    y: 62.74,
  },
  {
    name: "Feb",
    y: 10.57,
  },
  {
    name: "Mar",
    y: 7.23,
  },
  {
    name: "Apr",
    y: 5.58,
  },
  {
    name: "May",
    y: 4.02,
  },
  {
    name: "Jun",
    y: 1.92,
  },
  {
    name: "Jul",
    y: 10.62,
  },
  {
    name: "Aug",
    y: 70.62,
  },
  {
    name: "Sep",
    y: 1.62,
  },
  {
    name: "Oct",
    y: 45.2,
  },
  {
    name: "Nov",
    y: 12,
  },
  {
    name: "Dec",
    y: 54.3,
  },
];
const ColumnChart = ({ data = dataSet, height, ColumnChartRef }) => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          format: "{point.y:.1f}%",
        },
      },
    },

    tooltip: {
      headerFormat: " ",
      pointFormat: " <b>{point.y:.2f}</b> <br/>",
    },

    series: [
      {
        name: "",

        colorByPoint: false,
        color: "#CCEBA3",
        data,
      },
    ],
  };

  return (
      <HighchartsReact
        ref={ColumnChartRef}
        highcharts={Highcharts}
        options={options}
        containerProps={{
          style: { height: height ? height : "100%", width: "100%" },
        }}
      />
  );
};

export { ColumnChart };
