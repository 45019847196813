import React from "react";
import style from "./style.module.scss";
import { Tab, Nav } from "react-bootstrap";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

import { useTranslation } from "react-i18next";

import { Statistics } from "./Statistics";
import { Risks } from "./Risks";
import HeadP from "../../../components/finEdge/HeadP";

const StatisticsRisks = () => {
  return (
    <div>
      <div style={{ marginLeft: 68 }}>
        <h1 style={{ fontSize: 22, marginBottom: 27, marginTop: 50 }}>
          Statistiques et risques
        </h1>

       <HeadP/>
        <Tab.Container id="left-tabs-example" defaultActiveKey={"statistics"}>
          <Row>
            <Col style={{ marginLeft: "1px" }}>
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey={"statistics"} className="tab-anc">
                    Statistiques
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"risques"} className="tab-anc">
                    Risques
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey={"statistics"}>
                  <Statistics />
                </Tab.Pane>
                <Tab.Pane eventKey={"risques"}>
                  <Risks />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export { StatisticsRisks };
