import React, { useState } from "react";

import { LineZoomAbleChart } from "../../../components/charts/highcharts/LineZoomAbleChart";
import style from "./style.module.scss";

import DoughnutChart from "../../../components/charts/DoughnutChart";
import { ColumnNegativeChart } from "../../../components/charts/highcharts/ColumnNegativeChart";
import { LineZoomChart } from "../../../components/charts/highcharts/LineZoomChart";
import TableGrid from "../../../components/tables/tablegrid";
const data1 = [
  {
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },{
  "Date ":"01/02/2021",
  "Rdts benchmark":"-0,06%",
  "Rdts portfeuille":"-0,33%",
  "Perf bench":"100%",
  "Perf ptf":"100%"
  },
                 
];
const options1 =[
  
  {
    type: "search",
    width:100
  },
]
const Statistics = () => { 
  return (
    <div>
      <h4>Analyse des positions de portefeuille </h4>

      <div className={style.statisticsContainer}>
        <div className={style.statisticsRow1}>
          <h5
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#111137",
              marginBottom: "2rem",
              paddingLeft: 10,
            }}
          >
            Concentration par classe d'actifs
          </h5>
          <DoughnutChart
            data={[30, 70]}
            labels={["Actions", "Obligations"]}
            colors={["#A6CEE3", "#1F78B4"]}
          />
        </div>

        <div className={style.statisticsRow2}>
          <h5
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#111137",
              marginBottom: "2rem",
              paddingLeft: 10,
            }}
          >
            Concentration par secteur
          </h5>
          <DoughnutChart
            data={[20, 70, 30, 10]}
            labels={["Télécoms", "Industrie", "Banques", "Assurances"]}
            colors={["#33A02C", "#1F78B4", "#A6CEE3", "#B2DF8A"]}
          />
        </div>

        <div className={style.statisticsRow3}>
          <h5
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#111137",
              marginBottom: "2rem",
              paddingLeft: 10,
            }}
          >
            Pondération actifs
          </h5>
          <DoughnutChart
            data={[20, 70, 30, 10]}
            labels={["Nokia", "Facebook", "Amazon", "Alibaba"]}
            colors={["#E9DC5C", "#E96E6E", "#C6A0F7", "#5BE4EE"]}
          />
        </div>
      </div>
      <h5
        style={{
          paddingLeft: 20,
          fontSize: 18,
          fontWeight: "bold",
          marginTop: "1.5rem",
        }}
      >
        Comparaison de la performance Vs Benchmark
      </h5>
      <div className={style.performanceContainer}>
        <div className={[style.row1,"mr-4 pl-2"].join(" ")}>
          {/* <div
            style={{
              height: "795px",
              backgroundColor: "teal",
              marginLeft: 20,
              marginBottom: 20,
              marginRight: 20,
            }}
          ></div> */}
          <TableGrid  data={data1} options={options1} bodyStyle={{height:'740px'}} />

        </div>
        <div className={style.row2}>
          <div className={[style.priceChart].join(" ")}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                alignSelf: "center",
                fontWeight: "bold",
              }}
            >
              Comparaison de la performance par rapport à l'indice de référence
            </h5>
            <LineZoomChart
              height={320}
              name1={"Perf bench"}
              data1={[
                [7609, 0.7537],
                [7696, 0.7537],
                [7782, 0.7559],
                [7868, 0.7631],
                [7955, 0.7644],
                [8214, 0.769],
                [8300, 0.7683],
                [8387, 0.77],
                [8473, 0.7703],
              ]}
              color1={"#BEBEBE"}
              name2={"Perf ptf"}
              data2={[
                [7609, 0.7703],
                [7696, 0.7696],
                [7782, 0.7782],
                [7868, 0.7868],
                [7955, 0.7955],
                [8214, 0.8214],
                [8300, 0.83],
                [8387, 0.77],
                [8473, 0.8473],
              ]}
              color2={"#E5AB6B"}
              legendEnabled
              // disableZoom
            />
          </div>

          <div className={style.priceChart} style={{ marginTop: 30 }}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                alignSelf: "center",
                fontWeight: "bold",
              }}
            >
              Comparaison des rendements par rapport à l'indice de référence
            </h5>

            <ColumnNegativeChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Statistics };
