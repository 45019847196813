import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Card, Nav, Tab } from "react-bootstrap";
import { Breadcrumb, BreadcrumbItem, Button, Col, Row } from "reactstrap";

import { ColumnChart } from "../../../components/charts/highcharts/ColumnChart";
import { LineZoomAbleChart } from '../../../components/charts/highcharts/LineZoomAbleChart';
import DernierCours from '../../../components/finEdge/dernierCours';
import HeadP from '../../../components/finEdge/HeadP';
import TableGrid from '../../../components/tables/tablegrid';
import TableFinM from '../../../components/tables/tableM';
import TableFinEdge from '../../../components/tables/tableVueActifs';
import style from './style.module.scss'
import { OrderModal } from '../../../components/modal/FinEdgeModals/OerderModal/OrderModal';
import { LineZoomChart } from '../../../components/charts/highcharts/LineZoomChart';

function gArray (length){

  return Array.from(Array(length).keys())
}


const head=["Actif","Quantité","Prix d'achat","Dernier cours","Variation","Valorisation" , "P&L"]
const listRow = [
  {
    num:1,
    title:'Actions',
    total:"323.231,22",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231","42,213","+3,23%","323.231,22","323.231,22"
    ])
  },
  {
    num:2,
    title:'Obligations',
    total:"323.231,22",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231","42,213","-3,23%","323.231,22","323.231,22"
    ])
  },
  {
    num:3,
    title:'Actions',
    total:"323.231,22",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231","42,213","+3,23%","323.231,22","323.231,22"
    ])
  },
]
const head2=["Actif","Bid","Ask"]
const listRow2 = [
  {
    num:1,
    title:'Actions',
    total:"",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32 ,231","1010"
    ])
  },
  {
    num:2,
    title:'Obligations',
    total:"",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231"
    ])
  },
  {
    num:3,
    title:'Actions',
    total:"",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231"
    ])
  },
]

const head3=["Stats de la journée","#"]
const listRow3 = [
  {
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32 ,231","1010"
    ])
  },
  {

    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231"
    ])
  },
  {
   
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231"
    ])
  },
]

const head4=["Date","Achat/Vente","Quantité","Prix transaction","Montant"]
const listRow4 = [
  {
    list: gArray(5).map((elm)=> [
      "01/02/2021","Achat","120","32.21€","-2498"
    ])
  },
  {

    list: gArray(5).map((elm)=> [
      "01/02/2021","Achat","120","32.21€","-2498"
    ])
  },
  {
   
    list: gArray(5).map((elm)=> [
      "01/02/2021","Achat","120","32.21€","-2498"
    ])
  },
]


const data = [
  {
  "Date ":"01/02/2021",
  "Catégorie":"Action",
  "Actif":"Nokia",
  "Achat/Vente":"Vente",
  "Quantité":"120",
  "Prix de la transaction":"32.21€",
  "Position":"-2498",
  }, 
  {
      "Date ":"01/02/2021",
      "Catégorie":"Action",
      "Actif":"Nokia",
      "Achat/Vente":"Achat",
      "Quantité":"120",
      "Prix de la transaction":"32.21€",
      "Position":"-2498",
      }, 
      {
          "Date ":"01/02/2021",
          "Catégorie":"Action",
          "Actif":"Nokia",
          "Achat/Vente":"Vente",
          "Quantité":"120",
          "Prix de la transaction":"32123123.21€",
          "Position":"-2498",
          }, 
          {
              "Date ":"01/02/2021",
              "Catégorie":"Action",
              "Actif":"Nokia",
              "Achat/Vente":"Achat",
              "Quantité":"120",
              "Prix de la transaction":"32.21€",
              "Position":"-2498",
              }, 
              {
                  "Date ":"01/02/2021",
                  "Catégorie":"Action",
                  "Actif":"Nokia",
                  "Achat/Vente":"Achat",
                  "Quantité":"120",
                  "Prix de la transaction":"32.21€",
                  "Position":"-2498",
                  },                     

  
 
];
const options =[
  {type:"search"},
  {type:"select"},
  {type:"search"},
  {
      label:{
          "Achat":true,
          "Vente":false,
      },
  },
]

const data1 = [
  {
  "Date ":"01/02/2021",
  "Achat/Vente":"Action",
  "Quantité":"Nokia",
  "Prix transaction":"Vente",
  "Montant":"120"
  },
  {
    "Date ":"01/02/2021",
    "Achat/Vente":"Action",
    "Quantité":"Nokia",
    "Prix transaction":"Vente",
    "Montant":"120"
    },
    {
      "Date ":"01/02/2021",
      "Achat/Vente":"Action",
      "Quantité":"Nokia",
      "Prix transaction":"Vente",
      "Montant":"120"
      },
      {
        "Date ":"01/02/2021",
        "Achat/Vente":"Action",
        "Quantité":"Nokia",
        "Prix transaction":"Vente",
        "Montant":"120"
        },
        {
          "Date ":"01/02/2021",
          "Achat/Vente":"Action",
          "Quantité":"Nokia",
          "Prix transaction":"Vente",
          "Montant":"120"
          }                 
];
const options1 =[
  {width:100},
]

const data2 = [
  {
  "Stats de la journée":"Volume",
  "#":"32, 231"
  },     {
  "Stats de la journée":"Ouverture",
  "#":"32, 231"
  },     {
  "Stats de la journée":"Plus haut",
  "#":"32, 231"
  },     {
  "Stats de la journée":"Plus bas",
  "#":"32, 231"
  },     {
  "Stats de la journée":"Clôture veille",
  "#":"32, 231"
  },     {
  "Stats de la journée":"Volatilité",
  "#":"32, 231"
  },     {
  "Stats de la journée":"Volume",
  "#":"32, 231"
  },               
];
const options2 =[
 
]

const Title =({text})=>{

  return <p className="p-0 m-0" >{text}</p>
}




const Wallet = ({}) => {

  const [showOrder, setShowOrder] = useState(false);
  const [operationSens, setOperationSens] = useState("purchase");
  const [key, setKey] = useState('theory');

  // console.log(row)
  return (
    <div style={{marginLeft: "68px" , marginTop: "44px"}}>
     {showOrder && (
        <OrderModal
          show={showOrder}
          onHide={() => {
            setShowOrder(false);
          }}
          operation={"purchase"}
        />
      )}

     <div class="row">
       <h3 style={{paddingLeft: "12px"}} >Portefeuille </h3>
     </div>
     <HeadP />
      <div class="col-md-12">
      <Tab.Container id="left-tabs-example" 
       activeKey={key}
       onSelect={(k) => setKey(k)}
      >
        <Row>
          <Col style={{ marginLeft: "1px" }}>
            <Nav variant="pills" className="flex-row">
              <Nav.Item>
                <Nav.Link eventKey={"theory"} className="tab-anc">
                Portefeuille 
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={"rules"} className="tab-anc">
                Blotter
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey={"theory"}>
              {key ===  "theory" &&
              <>
                 <Row>

                   <Col sm={3}>
                   <Row className="mb-3">
                     <Col lg={10} style={{display: "flex",alignItems: "center",height:'37px'}} >
                        <Title text={"Vue marché"} />
                     </Col>
                     <Col lg={2} style={{display: "flex",alignItems: "center",height:'37px'}} >
                         <span class=""> <i class="fas fa-info-circle "></i> </span>
                     </Col>
                   </Row>
                    <TableFinEdge head={head2}  listRow={listRow2}/>
                   </Col>
                   <Col sm={9}>
                   <Row className="mb-3">
                     <Col lg={8} style={{display: "flex",alignItems: "center"}}  >
                        <Title text={"Vue des actifs"} />
                     </Col>
                     <Col lg={4}  >
                      <div className={style.btn_content} >
                          <button type="button" class="btn btn-info waves-effect waves-light width-md mr-2" style={{backgroundColor:"#5A97F8"}} onClick={()=>{
                            setShowOrder(true);
                          }} >
                          <i class=" fas fa-plus mr-1"></i>
                          Saisir un ordre
                          </button>
                          <button class="btn btn-icon waves-effect btn-secondary"> <i class="fas fa-sync-alt"></i> </button>
                      </div>
                     </Col>
                   </Row>
                   <TableFinEdge head={head}  listRow={listRow}/>
                   </Col>
                 </Row>
                  <Row className="mt-3" style={{backgroundColor:'#F7F7F7',paddingBottom:'10px'}} >
                    <Col lg={12}>
                      <h4>Vue d'un actif : Nokia</h4>
                    </Col>
                    <Col lg={6}>
                        <div className={style.priceChart} style={{backgroundColor:'#fff',paddingBottom:'30px'}}>
                            <h5
                              style={{
                                paddingLeft: 10,
                                paddingTop: 10,
                                fontSize: 16,
                                fontWeight: "bold",
                                marginTop:'0px'
                              }}
                            >
                              Prix
                            </h5>
                            <LineZoomChart height={280} />
                        </div>
                    </Col>
                    <Col lg={6}>
                          <Row>
                            <Col>
                              <TableGrid bodyStyle={{height:'285px'}}  data={data2} options={options2}  />
                            </Col>
                            <Col>
                               <DernierCours />
                            </Col>
                          </Row>   
                    </Col>
                    <Col lg={6}>
                        <div   className={style.priceChart}  style={{backgroundColor:'#fff',height:'305px',paddingTop:'5px'}}>
                           <h5 style={{ paddingLeft: 10 }}>Volumes</h5>
                           <ColumnChart />
                        </div>
                    </Col>
                    <Col lg={6}>
                       <TableGrid  data={data1} options={options1} bodyStyle={{height:'280px'}} />

                    </Col>
                  </Row>
             </>
              }
              </Tab.Pane>
              <Tab.Pane eventKey={"rules"}>
                  {key ===  "rules" &&
                    <TableGrid  data={data} options={options}  />
                  }
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      </div>
    </div>
  )
}

Wallet.propTypes = {

}

export default Wallet
