import React, { useState } from "react";

import style from "./style.module.scss";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { LineZoomAbleChart } from "../../../charts/highcharts/LineZoomAbleChart";
import { LineZoomChart } from "../../../charts/highcharts/LineZoomChart";
const RankingStatsModal = ({
  show,
  onHide = () => {},
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Classement : Vous Vs Roger Miller
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={style.container}>
          <div className={style.item}>
            <h5>Evolution du classement</h5>
            <LineZoomChart disableZoom />
          </div>
          <div className={style.item}>
            <h5>Historique des rendements (comparatif)</h5>
            <LineZoomChart
              disableZoom
              legendEnabled
              name1={"Rdts benchmark"}
              name2={"Rdts portefeuille (Roger Miller)"}
              name3={"Rdts portefeuille (Vous)"}
              data1={[
                [7609, 0.7537],
                [7696, 0.7537],
                [7782, 0.7559],
                [7868, 0.7631],
                [7955, 0.7644],
                [8214, 0.769],
                [8300, 0.7683],
                [8387, 0.77],
                [8473, 0.7703],
              ]}
              data2={[
                [7609, 0.7703],
                [7696, 0.7696],
                [7782, 0.7782],
                [7868, 0.7868],
                [7955, 0.7955],
                [8214, 0.8214],
                [8300, 0.83],
                [8387, 0.77],
                [8473, 0.8473],
              ]}
              data3={[
                [7609, 0.7703],
                [7696, 0.7696],
                [7782, 0.7782],
                [7868, 0.7868],
                [7955, 0.7955],
                [8214, 0.8214],
                [8300, 0.83],
                [8387, 0.77],
                [8473, 0.8473],
              ]}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { RankingStatsModal };
