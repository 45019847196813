import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
function getPointCategoryName(point, dimension) {
  var series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"]];
}

const dataSet = [
  {
    name: "Jan",
    y: 62.74,
  },
  {
    name: "Feb",
    y: 10.57,
  },
  {
    name: "Mar",
    y: 7.23,
  },
  {
    name: "Apr",
    y: 5.58,
  },
  {
    name: "May",
    y: 4.02,
  },
  {
    name: "Jun",
    y: 1.92,
  },
  {
    name: "Jul",
    y: 10.62,
  },
  {
    name: "Aug",
    y: 70.62,
  },
  {
    name: "Sep",
    y: 1.62,
  },
  {
    name: "Oct",
    y: 45.2,
  },
  {
    name: "Nov",
    y: 12,
  },
  {
    name: "Dec",
    y: 54.3,
  },
];
const ColumnNegativeChart = ({ data = dataSet }) => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "01 Jan",
        "02 Jan",
        "03 Jan",
        "04 Jan",
        "05 Jan",
        "06 Jan",
        "07 Jan",
        "08 Jan",
        "09 Jan",
        "10 Jan",
        "11 Jan",
        "12 Jan",
      ],
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: true,

      align: "center",
      verticalAlign: "top",
      borderWidth: 0,
    },

    series: [
      {
        name: "Rdts benchmark",
        data: [5, 3, 4, 7, 2, 3, 5, 4, 2, 3, 1, 2],
        color: "#1F78B4",
      },
      {
        name: "Rdts portfeuille",
        data: [-2, -2, -3, -2, -1, -1, -2, -5, -7, -4, -3, -5],
        color: "#ED7D31",
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { height: "100%", width: "100%" } }}
    />
  );
};

export { ColumnNegativeChart };
