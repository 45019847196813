import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const dataSet1 = [
  [7609, 0.7537],
  [7696, 0.7537],
  [7782, 0.7559],
  [7868, 0.7631],
  [7955, 0.7644],
  [8214, 0.769],
  [8300, 0.7683],
  [8387, 0.77],
  [8473, 0.7703],
];
const dataSet2 = [
  [7609, 0.7703],
  [7696, 0.7696],
  [7782, 0.7782],
  [7868, 0.7868],
  [7955, 0.7955],
  [8214, 0.8214],
  [8300, 0.83],
  [8387, 0.77],
  [8473, 0.8473],
];
const LineZoomChart = ({
  height,
  data1 = dataSet1,
  name1 = "Evolution de la volatilité",
  color1 = "#000",
  data2,
  name2 = "Evolution du Beta vs benchmark",
  color2 = "#1F78B4",
  disableZoom,
  legendEnabled,
  onZoomed = () => {},
  onResetZoom = () => {},
  LineZoomAbleChartRef
}) => {
  const options = {
    chart: {
      zoomType: disableZoom ? "none" : "x",

      // events: {
      //   selection: function (event) {
      //     // log the min and max of the primary, datetime x-axis
      //     alert("öööööö");

      //     // log the min and max of the y axis
      //     console.log(event);
      //   },

      // },
    },
    title: {
      text: "",
    },
    subtitle: {
      text: disableZoom
        ? ""
        : document.ontouchstart === undefined
        ? "Click and drag in the plot area to zoom in"
        : "Pinch the chart to zoom in",
    },
    xAxis: {
      events: {
        // afterSetExtremes: function (e) {
        //   console.log(`this.min`, this.min);
        //   console.log(`this.max`, this.max);
        //   console.log(`this.dataMax`, this.dataMax);
        //   console.log(`this.dataMin`, this.dataMin);
        //   if (typeof e.min == 'undefined' && typeof e.max == 'undefined')
        //     // {
        //     //   alert("kkk");
        //     //   onResetZoom();
        //     // }
        //     alert("reset Zoom");
        //   else alert(" Zoom");

        //   // onZoomed(this.min, this.max);
        // },
        setExtremes: function (e) {
          console.log(`this.min`, this.min);
          console.log(`this.max`, this.max);
          console.log(`this.dataMax`, this.dataMax);
          console.log(`this.dataMin`, this.dataMin);
          if (typeof e.min == "undefined" && typeof e.max == "undefined")
            onResetZoom();
          else onZoomed(this.min, this.max);
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      enabled: legendEnabled,

      align: "center",
      verticalAlign: "top",
      borderWidth: 0,
    },
    series: [
      {
        type: "",
        name: name1,
        data: data1,
        color: color1,
      },
    ],
  };

  data2 &&
    options.series.push({
      type: "",
      name: name2,
      data: data2,
      color: color2,
    });

  return (
    <HighchartsReact
      ref={LineZoomAbleChartRef}
      highcharts={Highcharts}
      options={options}
      immutable
      containerProps={{
        style: { height: height ? height : "100%", width: "100%" },
      }}
    />
  );
};

export { LineZoomChart };
