
const day3 = [
    {
      id:87,
      category: 1
    },
    {
      id:88,
      category: 1
    },
    {
      id:89,
      category: 1
    },
    // {
    //   id:90,
    //   category: 1
    // },
    {
      id:91,
      category: 1
    },
    // {
    //   id:92,
    //   category: 1
    // },
    // {
    //   id:93,
    //   category: 2
    // },
    {
      id:94,
      category: 2
    },
    // {
    //   id:95,
    //   category: 2
    // },
    {
      id:96,
      category: 2
    },
    {
      id:97,
      category: 2
    },
    {
      id:98,
      category: 2
    },
    {
      id:99,
      category: 3
    },
    // {
    //   id:100,
    //   category: 3
    // },
    {
      id:101,
      category: 3
    },
    {
      id:102,
      category: 3
    },
    // {
    //   id:103,
    //   category: 3
    // },
    // {
    //   id:104,
    //   category: 3
    // },
    {
      id:105,
      category: 3
    },
    {
      id:106,
      category: 4
    },
    // {
    //   id:107,
    //   category: 4
    // },
    // {
    //   id:108,
    //   category: 4
    // },
    {
      id:109,
      category: 4
    },
    {
      id:110,
      category: 4
    },
    {
      id:111,
      category: 4
    },
    // {
    //   id:112,
    //   category: 5
    // },
    {
      id:113,
      category: 5
    },
    {
      id:114,
      category: 5
    },
    {
      id:115,
      category: 5
    },
    // {
    //   id:116,
    //   category: 5
    // },
    {
      id:117,
      category: 5
    },
    {
      id:118,
      category: 6
    },
    {
      id:119,
      category: 6
    },
    {
      id:120,
      category: 6
    },
    // {
    //   id:121,
    //   category: 6
    // },
    {
      id:122,
      category: 6
    }

  ]


  export {day3}