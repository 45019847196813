import React, { useState } from "react";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import { ColumnNegativeChart } from "../../../components/charts/highcharts/ColumnNegativeChart";
import { HeatMapChart } from "../../../components/charts/highcharts/HeatMapChart";
import { LineZoomAbleChart } from "../../../components/charts/highcharts/LineZoomAbleChart";
import { LineZoomChart } from "../../../components/charts/highcharts/LineZoomChart";
import TableGrid from "../../../components/tables/tablegrid";
import TableFinEdge from "../../../components/tables/tableVueActifs";
import style from "./style.module.scss";

function gArray (length){

  return Array.from(Array(length).keys())
}
const data1 = [
  {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  }, {
  "Date ":"01/02/2021",
  "Evolution de la volatilité":"-0,06%",
  "Evolution du Beta vs benchmark":"100%"
  },             
];
const options1 =[
  
  {
    type: "search",
    width:100
  },
]

const head=["Actif","Quantité","Prix d'achat","Dernier cours","Variation","Valorisation" , "P&L"]
const listRow = [
  {
    num:1,
    title:'Actions',
    total:"323.231,22",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231","42,213","+3,23%","323.231,22","323.231,22"
    ])
  },
  {
    num:2,
    title:'Obligations',
    total:"323.231,22",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231","42,213","-3,23%","323.231,22","323.231,22"
    ])
  },
  {
    num:3,
    title:'Actions',
    total:"323.231,22",
    list: gArray(5).map((elm)=> [
      "Nokia",1000,"32, 231","42,213","+3,23%","323.231,22","323.231,22"
    ])
  },
]
const Risks = () => {
  const [showOrder, setShowOrder] = useState(false);
  const [operationSens, setOperationSens] = useState("purchase");
  return (
    <div>
      <h4>Indicateurs de risque </h4>

      <div className={style.riskContainer}>
        <div className={style.riskRows}>
          <h5 className={style.textIndicateur}>Volatilité</h5>
          <h3 className={style.percentageIndicateur}>20%</h3>
          <span class="badge badge-success badge-pill float-left pl-2 pr-2">
            +2.1 <i class="ml-2 fas fa-caret-up"></i>{" "}
          </span>
        </div>
        <div className={style.riskRows}>
          <h5 className={style.textIndicateur}>Ratio de Sharpe</h5>
          <h3 className={style.percentageIndicateur}>0.7</h3>
          <span class="badge badge-success badge-pill float-left pl-2 pr-2">
            +0.1 <i class="ml-2 fas fa-caret-up"></i>{" "}
          </span>
        </div>
        <div className={style.riskRows}>
          <h5 className={style.textIndicateur}>Max DD</h5>
          <h3
            className={style.percentageIndicateur}
            style={{ color: "#E04040" }}
          >
            -45%
          </h3>
          {/* <span class="badge badge-success badge-pill float-left pl-2 pr-2">
            0.1% <i class="ml-2 fas fa-caret-up"></i>{" "}
          </span> */}
        </div>
        <div className={style.riskRows}>
          <h5 className={style.textIndicateur}>VaR 95%</h5>
          <h3
            className={style.percentageIndicateur}
            style={{ color: "#E04040" }}
          >
            -100.000€
          </h3>
          <span
            class="badge badge-danger badge-pill float-left pl-2 pr-2"
            style={{ backgroundColor: "#E04040" }}
          >
            -1400 <i class="ml-2 fas fa-caret-down"></i>{" "}
          </span>
        </div>
        <div className={style.riskRows}>
          <h5 className={style.textIndicateur}>VaR 99%</h5>
          <h3
            className={style.percentageIndicateur}
            style={{ color: "#E04040" }}
          >
            -200.000€
          </h3>
          <span
            class="badge badge-danger badge-pill float-left pl-2 pr-2"
            style={{ backgroundColor: "#E04040" }}
          >
            -1750 <i class="ml-2 fas fa-caret-down"></i>{" "}
          </span>
        </div>
        <div className={style.riskRows}>
          <h5 className={style.textIndicateur}>Beta</h5>
          <h3 className={style.percentageIndicateur}>0,95</h3>
          <span
            class="badge badge-danger badge-pill float-left pl-2 pr-2"
            style={{ backgroundColor: "#E04040" }}
          >
            -0,34 <i class="ml-2 fas fa-caret-down"></i>{" "}
          </span>
        </div>
      </div>
      <h5
        style={{
          paddingLeft: 20,
          fontSize: 18,
          fontWeight: "bold",
          marginTop: "1.5rem",
        }}
      >
        Evolution de la volatilité et Beta
      </h5>
      <div className={style.performanceContainer}>
        <div className={[style.row1,"mr-4"].join(" ")}>
          {/* <div
            style={{
              height: "383px",
              backgroundColor: "thistle",
              marginLeft: 20,
              marginBottom: 20,
              marginRight: 20,
            }}
          ></div> */}
          <TableGrid  data={data1} options={options1} bodyStyle={{height:'290px'}} />

        </div>
        <div className={style.row2}>
          <div className={style.priceChart}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                alignSelf: "flex-start",
                fontWeight: "bold",
              }}
            >
              Evolution de la volatilité du ptf + Beta
            </h5>
            <LineZoomChart
              height={320}
              name1={"Evolution de la volatilité"}
              data1={[
                [7609, 0.7537],
                [7696, 0.7537],
                [7782, 0.7559],
                [7868, 0.7631],
                [7955, 0.7644],
                [8214, 0.769],
                [8300, 0.7683],
                [8387, 0.77],
                [8473, 0.7703],
              ]}
              color1={"#BEBEBE"}
              name2={"Evolution du Beta vs benchmark"}
              data2={[
                [7609, 0.7703],
                [7696, 0.7696],
                [7782, 0.7782],
                [7868, 0.7868],
                [7955, 0.7955],
                [8214, 0.8214],
                [8300, 0.83],
                [8387, 0.77],
                [8473, 0.8473],
              ]}
              color2={"#E5AB6B"}
              legendEnabled
              disableZoom
            />
          </div>
        </div>
      </div>
      <h5
        style={{
          paddingLeft: 20,
          fontSize: 18,
          fontWeight: "bold",
          marginTop: "1.5rem",
        }}
      >
        Analyse détaillé des risques du portefeuille
      </h5>
      <div className={style.riskAnalyseContainer}>
        {/* <div
          style={{
            height: "540px",
            backgroundColor: "thistle",
            marginLeft: 20,
            marginBottom: 20,
            marginRight: 20,
            flex: 1,
          }}
        ></div> */}
        <TableFinEdge head={head}  listRow={listRow}/>

      </div>

      <h5
        style={{
          paddingLeft: 20,
          fontSize: 18,
          fontWeight: "bold",
          marginTop: "1.5rem",
        }}
      >
        Matrice de corrélation
      </h5>

      <div className={style.matriceContainer}>
        <div
          style={{
            height: "540px",
            backgroundColor: "thistle",
            marginLeft: 20,
            marginBottom: 20,
            marginRight: 20,
            flex: 1,
          }}
        >
          <HeatMapChart
            data={[
              [0, 0, 100],
              [0, 1, -20],
              [0, 2, 80],
              [0, 3, 99],
              [1, 0, -20],
              [1, 1, 100],
              [1, 2, 10],
              [1, 3, -100],
              [2, 0, 80],
              [2, 1, 10],
              [2, 2, 100],
              [2, 3, 88],
              [3, 0, 99],
              [3, 1, -100],
              [3, 2, 88],
              [3, 3, 100],
            ]}
            categories={["A", "B", "C", "D"]}
          />
        </div>
      </div>
    </div>
  );
};

export { Risks };
