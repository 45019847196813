import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Translation, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Card, CardBody, Col, Row, Tooltip } from "reactstrap";

import image1 from "../../../assets/images/imgCart/1.jpg";
// import image10 from "../../../assets/images/imgCart/10.jpeg";
// import image2 from "../../../assets/images/imgCart/2.jpeg";
//import image2 from "../../../assets/images/imgCart/2.png";
import image3 from "../../../assets/images/imgCart/3.jpg";
import image4 from "../../../assets/images/imgCart/4.jpg";
import image5 from "../../../assets/images/imgCart/5.jpg";
import image6 from "../../../assets/images/imgCart/6.jpg";
import image7 from "../../../assets/images/imgCart/7.jpg";
import image8 from "../../../assets/images/imgCart/8.jpg";
import image9 from "../../../assets/images/imgCart/9.jpg";
import image10 from "../../../assets/images/imgCart/10.jpg";
import Loader from "../../../components/Loader";
import { encrypt } from "../../../helpers/crypto";
import { parse } from "../../../helpers/func";
import { getAllModules, getModuleByIDSuccess } from "../../../redux/actions";

const list_img = [image1,image3,image4,image5,image6,image7,image8, image9, image10];


const ShowModule = (props) => {
  const {
    moduleName,
    moduleField,
    numberOfSessions,
    description,
    numberOfConfigurations,
    creationDate,
    clientName,
    businessGame,
    gameLogoPath
  } = props.module;
  const {t} = useTranslation();
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // backdrop={"static"}
    >
      <Modal.Body>
        <div
          className="card-box project-box p-0 mb-0"
          style={{ backgroundColor: "#fdfdfd" }}
        >
          {businessGame && (
            <div className="float-right">
              <img
                src={gameLogoPath}
                width={25}
                style={{ borderRadius: "50%" }}
                alt=""
              />
            </div>
          )}

          <h4 className="mt-0 mb-0">{moduleName}</h4>
          <p
            className="text-success text-uppercase font-13"
            style={{ marginBottom: "5px", marginTop: "5px" }}
          >
            {moduleField}
          </p>
          <h6
            style={{
              fontSize: "12px",
              color: "#BCBCBC",
              font: "Regular 12px Karla",
              letterSpacing: "0.24px",
              opacity: "1",
              marginTop: "0px",
            }}
          >
             {t('moderatorModule.createdAt')} {creationDate}
          </h6>

          <h5 className="mt-0 mb-2">{t('moderatorModule.clientName')} : {clientName}</h5>

          <p className="text-muted font-13 mb-0 ">{description}</p>

          <ul className="list-inline mb-0">
            <li className="list-inline-item mr-4">
              <h4 className="mb-0">{parse(numberOfSessions)}</h4>
              <p className="text-muted">Sessions</p>
            </li>
            <li className="list-inline-item">
              <h4 className="mb-0">{parse(numberOfConfigurations)}</h4>
              <p className="text-muted">Configurations</p>
            </li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const CardModule = (props) => {
  const dispatch = useDispatch()
  const {
    moduleAffectationId,
    moduleName,
    moduleField,
    description,
    numberOfConfigurations,
    numberOfSessions,
    creationDate,
    moduleId,
    clientId,
    gameLogoPath,
    clientName,
    businessGame,
    gameName
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  return (
    <Translation>
      {(t) => (
        <Card>
          <CardBody
            className="card-box project-box mb-0 pb-0"
            style={{ cursor: "pointer" }}
            onClick={() => {
              // console.log("----props----",props)
              props.onPress(props.item);
            }}
          >
            {businessGame && (
              <div className="dropdown float-right">
                <div
                  className="dropdown-toggle arrow-none card-drop"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <i className="mdi mdi-dots-vertical"></i> */}
                  <img
                    src={gameLogoPath}
                    width={25}
                    style={{ borderRadius: "50%" }}
                    alt=""
                  />
                </div>
              </div>
            )}
            <h4 className="mt-0 mb-0" style={{
              'overflow': 'hidden',
              'textOverflow': 'ellipsis',
              display: '-webkit-box',
              'WebkitLineClamp': '1',
              'WebkitBoxOrient': 'vertical'
            }}>{moduleName}</h4>
            <p
              className="text-success text-uppercase font-13"
              style={{ marginBottom: "5px", marginTop: "5px" }}
            >
              {moduleField}
            </p>
            <h6
              style={{
                fontSize: "12px",
                color: "#BCBCBC",
                font: "Regular 12px Karla",
                letterSpacing: "0.24px",
                opacity: "1",
                marginTop: "0px",
              }}
            >
              {t('moderatorModule.createdAt')}  {creationDate}
            </h6>

            <p
              className="text-muted font-13 mb-0 about-container"
              style={{ height: "3.0em" }}
            >
              {description}...
            </p>

            <ul className="list-inline mb-0">
              <li className="list-inline-item mr-4">
                <h4 className="mb-0">{parse(numberOfSessions)}</h4>
                <p className="text-muted">Sessions</p>
              </li>
              <li className="list-inline-item">
                <h4 className="mb-0">{parse(numberOfConfigurations)}</h4>
                <p className="text-muted">Configurations</p>
              </li>
            </ul>
          </CardBody>
          <div className="tool-box-container">
            <Link
              id="sessions"
               to={ `/moderator/modules/${encrypt(moduleAffectationId)}/sessions`}
              onClick={() => {
                dispatch(
                  getModuleByIDSuccess(
                    {
                      moduleAffectationId,
                      moduleName,
                      moduleField,
                      description,
                      numberOfConfigurations,
                      numberOfSessions,
                      creationDate,
                      moduleId,
                      clientId,
                      gameLogoPath,
                      clientName,
                      businessGame,
                      gameName
                    },
                    []
                  )
                );
              }}
              className="tool-box"
            >
              <i className="fas fa-chalkboard-teacher  fa-2x" />

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="sessions"
                toggle={toggle}
              >
                sessions
              </Tooltip>
            </Link>

            <Link
              id="config"
              to={`/moderator/modules/${encrypt(moduleAffectationId)}/config`}
              onClick={() => {
                dispatch(
                  getModuleByIDSuccess(
                    {
                      moduleAffectationId,
                      moduleName,
                      moduleField,
                      description,
                      numberOfConfigurations,
                      numberOfSessions,
                      creationDate,
                      moduleId,
                      clientId,
                      gameLogoPath,
                      clientName,
                      businessGame,
                      gameName
                    },
                    []
                  )
                );
              }}
              className="tool-box"
            >
              <i className="fas fa-cogs  fa-2x" />
              <Tooltip
                placement="top"
                isOpen={tooltipOpen2}
                target="config"
                toggle={toggle2}
              >
                configurations
              </Tooltip>
            </Link>
          </div>
        </Card>
      )}
    </Translation>
  );
};

const CardModuleGp = ({item , onClick=()=>null , groupe , index,indexs_img}) =>{

  return (
              <Card onClick={()=>onClick(item)} style={{"height":"100%",cursor: "pointer"}}>

                  <div className="card__image card__image__inner">
                      <img
                       alt=""
                         src={indexs_img[index]}
                         className="card__image__inner"
                         style={{
                                "width": "100%",
                                "height": "120px",
                                "objectFit": "cover"
                                }} />

                  </div>
                  <CardBody className="card-box project-box mb-0 p-2">
                      <h5 className="mt-0" style={{
                                      lineHeight: "1.5em",
                                      height: "3em",
                                      overflow: "hidden"
                                }}>
                        {item}
                      </h5>
                      <p style={{
                                      lineHeight: "1.5em",
                                      height: "4em",
                                      overflow: "hidden"
                                }}>
                      {groupe[item][0].description}
                      </p>
                  </CardBody>
              </Card>
  )
}

const Module = (props) => {
  const [modules_, setModules] = useState(null);
  const [selectedOption] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [module, setModule] = useState({});
  const [showType, setShowType] = useState(true);
  const [moduleFieldSelected, setModuleFieldSelected] = useState("");

  const dispatch = useDispatch();

  const { modules, loading, fields, user } = useSelector((state) => {
    const fields = [];
    state.Module.modules.forEach((module) => {
      fields.push(module.moduleField);
    });

    return {
      modules: state.Module.modules,
      loading: state.Module.loading,
      fields: [...new Set(fields)],
      user: state.Auth,
    };
  });

  useEffect(() => {
    dispatch(getAllModules(user.id));

    return () => {};
  }, []);

  const handleChange = (selectedOption) => {
    //    setSelectedOption(selectedOption);
    //    setModules(props.modules);
    console.log("selectedOption",selectedOption)
    filter(selectedOption);
  };

  const generateOptions = (t) => {
    const options = [{ value: null, label: t("dashboard.allModules") }];
    fields.map((item) => {
      return options.push({ value: item, label: item });
    });

    return options;
  };

  const searchModules = (value) => {
    // const search_ = new RegExp(value, "i"); // prepare a regex object
    const res2 = modules.filter((item) => {
      return (
        item.moduleField.toLowerCase().includes(moduleFieldSelected.toLowerCase()) &&
        (item.description.toLowerCase().includes(value.toLowerCase())||
        item.moduleName.toLowerCase().includes(value.toLowerCase()))

      );
    });
    setModules(res2);
  };

  const filter = (selectedOption) => {
    if (selectedOption.value == null) return setModules(modules);

    const search_ = new RegExp(selectedOption.value, "i");
    let res = modules.filter((item) => {
      return search_.test(item.moduleField);
    });

    setModules(res);
  };

  const handleClickType = (value) => {

    const search_ = new RegExp(value, "i");

    let res = modules.filter((item) => {
      return item.moduleField === value;
    });

    setModuleFieldSelected(value)
    setModules(res);

    setShowType(prv=>!prv)
  }

  const groupe = _.mapValues(_.groupBy(modules, 'moduleField') );


  let i = 0;
  const indexs_img = [];

  const lst =  Object.keys(groupe).map((item,index)=>{
    if(i === 10) i = 0;
     indexs_img.push(list_img[i])
    i++;
  })



  return (
    <Translation>
      {(t) => (
        <React.Fragment>
          <ShowModule
            show={modalShow}
            module={module}
            onHide={() => {
              setModalShow(false);
            }}
          />

          <div className="">
            {loading && <Loader />}
            <Row className="mb-1">
              <Col lg={3}>
                {/* <ul className="list-unstyled topnav-menu topnav-menu-left m-0"> */}
                <h4 className="mt-2">{t("dashboard.modules")}</h4>
                {/* </ul>  */}
              </Col>
              <Col lg={9}>
                <div
                  className="d-flex justify-content-end controls-bar"
                  style={{ flexWrap: "wrap" }}
                >
                  <div className="p-1 flex-grow-1 "></div>
                 {!showType &&  <div
                    className="p-1 flex-grow-2 "
                    style={{ maxWidth: "254px" }}
                  >
                    <div className="input-group">
                      <input
                        onChange={(e) => {
                          searchModules(e.target.value);
                        }}
                        type="text"
                        className="form-control search-container"
                        placeholder={t("dashboard.searchLabel") + "..."}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn search-button"
                          type="submit"
                          onClick={searchModules}
                        >
                          <i className="fe-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>}


                </div>
              </Col>
            </Row>
           {! showType &&
           <Row>
              <Col className="mb-2">
                 <i onClick={()=>setShowType(prv=>!prv)} className=" fas fa-arrow-left fa-2x" style={{cursor: "pointer"}} ></i>
              </Col>
            </Row>}
            <Row>
            { showType ? Object.keys(groupe).map((item,index)=>{
                                return (
                                    <Col xl={3} key={""+index}  >
                                      <CardModuleGp indexs_img={indexs_img} groupe={groupe} index={index} item={item} onClick={handleClickType} />
                                    </Col>
                                )
                           })

              :


                modules_.map((item, index) => {
                    return (
                      <Col xl={4} key={"" + index}>
                        <CardModule {...item} t={t}  item={item} onPress={(item) => {
                            setModule(item);
                            setModalShow(true);
                          }}/>
                      </Col>
                    );
                  })
                }
            </Row>
          </div>
        </React.Fragment>
      )}
    </Translation>
  );
};

export default Module;
