import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import style from './style.module.scss'


const Searchable = ({
    value
})=>{

    return (
        <>
        {value}
        <input type="text" />
       </>
    )
}


const Selectable = ({
    value
})=>{

    return (
        <>
        {value}
        <select  >
            <option value="dog">Dog</option>
            <option value="cat">Cat</option>
            <option value="hamster">Hamster</option>
            <option value="parrot">Parrot</option>
            <option value="spider">Spider</option>
            <option value="goldfish">Goldfish</option>
        </select>
       </>
    )
}

const Label = ({child , value})=>{

    const elm = Object.entries(child).find(([key, val],index) => value.includes(key) )
    return(
        <span className={`badge badge-${elm[1] ? "success" : "danger"} badge-pill   pl-2 pr-2`}>
            {value} <i className={`ml-2 fas fa-caret-${elm[1] ? "up" : "down"}`}></i> 
        </span>
    )
}


const data2 = [
    {
    "Stats de la journée":"Volume",
    "#":"32, 231"
    },    
    {
    "Stats de la journée":"Ouverture",
    "#":"32, 231"
    },     
    {
    "Stats de la journée":"Plus haut",
    "#":"32, 231"
    },     
    {
    "Stats de la journée":"Plus bas",
    "#":"32, 231"
    },     
    {
    "Stats de la journée":"Clôture veille",
    "#":"32, 231"
    },     
    {
    "Stats de la journée":"Volatilité",
    "#":"32, 231"
    },     
    {
    "Stats de la journée":"Volume",
    "#":"32, 231"
    },               
  ];


const TableGrid = ({data,options,bodyStyle = {}}) => {
    

    const refTable = useRef();

    const [state, setRet] = useState(false);

    const itemEls = useRef(new Array())

    const columnLength = Object.entries(data[0]).length;

    const pushRef = (element)=>{
        if(itemEls.current.length <= columnLength )
        {
             itemEls.current.push(element)
             console.log('start',element?.offsetWidth)
        }
        
        if(itemEls.current.length === columnLength  && !state){
            setRet(!state);
        }
           
       
    }

    return (
        <table border='0' className={style.tbl_cnt} ref={refTable} >
        <thead>
        <tr>
          {

            Object.entries(data[0]).map(([key, val],index) => {
              return  <td key={key} ref={pushRef} style={{width:`${options[index]?.width  ? options[index].width+'px' : ''}`}}> 
                    {options[index]?.type === 'search' ? 
                    
                      ( <Searchable value={key} /> )
                    
                    : options[index]?.type === 'select' ? 
                    
                      (<Selectable value={key} />)
                    
                    :

                    <>
                    {key}
                    </>
                    }
             </td>
            })
          }
        </tr>
        </thead>
        <tbody style={{ ...bodyStyle}} >
        {
            itemEls.current.length === columnLength &&
            data.map((elem)=>{
                return  <tr >
                            {
                                Object.entries(elem).map(([key, val],index) => {
                                    return ( 
                                        <td key={index} style={{width:`${itemEls.current[index]?.offsetWidth}px`}} >
                                            {
                                                    !options[index]?.label ?
                                                        <>
                                                            {
                                                                val
                                                            }
                                                        </>
                                                        :
                                                    <Label child={options[index]?.label} value={val} />
                                            }
                                        </td>)
                                })
                            }
                        </tr>
            })
        }

        </tbody>
        </table>
    );
};


TableGrid.propTypes = {

};


export default TableGrid;
