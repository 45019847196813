import React from "react";
import style from "./style.module.scss";
import { Tab, Nav } from "react-bootstrap";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

import { useTranslation } from "react-i18next";
import { MarketViewItem } from "./MarketViewItem";
import HeadP from "../../../components/finEdge/HeadP";

const MarketView = () => {
  return (
    <div>
      <div style={{ marginLeft: 68 }}>
        <h1 style={{ fontSize: 22, marginBottom: 27, marginTop: 50 }}>
          Vue des marchés
        </h1>
          <HeadP />
        {/* <div
          style={{
            height: 115,
            width: "100%",
            backgroundColor: "pink",
            marginBottom: 20,
          }}
        ></div> */}
        <Tab.Container id="left-tabs-example" defaultActiveKey={"SX5E"}>
          <Row>
            <Col style={{ marginLeft: "1px" }}>
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey={"SX5E"} className="tab-anc">
                    SX5E
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"S&P_500"} className="tab-anc">
                    S&P 500
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey={"SX5E"}>
                  <MarketViewItem  marketValue={{value: "SX5E", label: "SX5E"}}/>
                </Tab.Pane>
                <Tab.Pane eventKey={"S&P_500"}>
                  <MarketViewItem  marketValue={{value: "S&P_500", label: "S&P 500"}}/>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export { MarketView };
