import React, { useState } from "react";
import { Button } from "reactstrap";
import { ColumnChart } from "../../../components/charts/highcharts/ColumnChart";
import { LineZoomAbleChart } from "../../../components/charts/highcharts/LineZoomAbleChart";
import { LineZoomChart } from "../../../components/charts/highcharts/LineZoomChart";
import { OrderModal } from "../../../components/modal/FinEdgeModals/OerderModal/OrderModal";
import TableGrid from "../../../components/tables/tablegrid";
import style from "./style.module.scss";

const data1 = [
  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },  {
  "Actif ":"Nokia",
  "Dernier cours":"1000",
  "Variation":"+3,23%",
  "Open":"42,213",
  "Haut":"42,213",
  "Bas":"323.231,22",
  "Volume":"323.231,22"
  },            
];
const options1 =[
  {
    type: "search",
    width:100
  },
  {
    type: "",
  },
  {
    label:{
        "-":true,
        "+":false,
    },
  }
]



const MarketViewItem = ({marketValue}) => {
  const [showOrder, setShowOrder] = useState(false);
  const [operationSens, setOperationSens] = useState("purchase");
  const [selectedMarket, setSelectedMarket] = useState(marketValue);
  return (
    <div>
      {showOrder && (
        <OrderModal
          show={showOrder}
          onHide={() => {
            setShowOrder(false);
          }}
          operation={operationSens}
          selectedMarket={selectedMarket}
        />
      )}
      <h4>Evolution du marché</h4>

      <div className={style.marketProgressContainer}>
        <div className={style.marketProgressRow1}>
          <div className={style.priceContainer}>
            <h5 style={{ paddingLeft: 10 }}>Prix</h5>
            <LineZoomChart />
          </div>
          <div className={style.volumesContainer}>
            <h5 style={{ paddingLeft: 10 }}>Volumes</h5>

            <ColumnChart />
          </div>
        </div>
        <div className={style.marketProgressRow2}>
          <div className={style.headerTicket}>Plus fortes hausses #</div>
          <RankGreenLine title={"Nokia"} rank={"+3,23%"} />
          <RankGreenLine title={"Nokia"} rank={"+3,23%"} />
          <RankGreenLine title={"Microsoft"} rank={"+3,23%"} />
          <RankGreenLine title={"Alibaba"} rank={"+3,23%"} />
          <RankGreenLine title={"Amazon"} rank={"+3,23%"} />
          <RankGreenLine title={"Apple"} rank={"+3,23%"} />
          <RankGreenLine title={"Google"} rank={"+3,23%"} />
        </div>

        <div className={style.marketProgressRow3}>
          <div className={style.headerTicket}>Plus fortes baisses #</div>
          <RankRedLine title={"Nokia"} rank={"-3,65%"} />
          <RankRedLine title={"Nokia"} rank={"-3,65%"} />
          <RankRedLine title={"Microsoft"} rank={"-3,23%"} />
          <RankRedLine title={"Alibaba"} rank={"-3,23%"} />
          <RankRedLine title={"Amazon"} rank={"-3,23%"} />
          <RankRedLine title={"Apple"} rank={"-3,23%"} />
          <RankRedLine title={"Google"} rank={"-3,23%"} />
        </div>
      </div>

      <div className={style.performanceContainer}>
        <div className={[style.row1,"mr-4"].join(" ")}>
          <h5 style={{ paddingLeft: 10, fontSize: 18, fontWeight: "bold" }}>
            Tableau des performances
          </h5>

          {/* <div
            style={{
              height: "742px",
              backgroundColor: "teal",
              margin: 20,
            }}
          ></div> */}
          <TableGrid  data={data1} options={options1} bodyStyle={{height:'665px'}} />

        </div>
        <div className={style.row2}>
          <h5 style={{ paddingLeft: 10, fontSize: 18, fontWeight: "bold" }}>
            Vue d'un actif : Nokia
          </h5>

          <div className={style.priceChart}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Prix
            </h5>
            <LineZoomChart height={280} />
          </div>

          <div className={style.volumeChart}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Volumes
            </h5>

            <ColumnChart height={100} />
          </div>

          <div className={style.tradeRow}>
            <div className={style.statsJourney}>
              <div className={style.headerTicket} style={{ height: 39 }}>
                Stats de la journée #
              </div>
              <RankLineText title={"Volume"} rank={"32,231"} height={39} />
              <RankLineText title={"Ouverture"} rank={"32,231"} height={39} />
              <RankLineText title={"Plus haut"} rank={"32,231"} height={39} />
              <RankLineText title={"Plus bas"} rank={"32,231"} height={39} />
              <RankLineText
                title={"Clôture veille"}
                rank={"32,231"}
                height={39}
              />
              <RankLineText title={"Volatilité"} rank={"32,231"} height={39} />
            </div>
            <div className={style.trade}>
              <h5
                style={{ fontSize: 16, fontWeight: "bold", color: "#111137" }}
              >
                Dernier cours
              </h5>
              <h5
                style={{ fontSize: 14, fontWeight: "normal", color: "#98A6B5" }}
              >
                16/02/2021 22:34:22
              </h5>

              <div className={style.tradePrice}>
                <h2
                  style={{
                    fontSize: 34,
                    fontWeight: "bold",
                    color: "#343A40",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  212,21€
                </h2>
                <div className={style.rankGreen}>
                  <p>
                    32% <i className="fas fa-caret-up"></i>
                  </p>
                </div>
              </div>
              <h5 className={style.variation}>
                Variation par rapport a la veille{" "}
              </h5>

              <Button
                className={style.purchaseButton}
                onClick={() => {
                  setOperationSens("purchase");
                  setShowOrder(true); 
                }}
              >
                Acheter
              </Button>
              <Button
                className={style.saleButton}
                onClick={() => {
                  setOperationSens("sale");
                  setShowOrder(true);
                }}
              >
                Vendre
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RankGreenLine = ({ title, rank }) => {
  return (
    <div className={style.lineTicket}>
      <p>{title}</p>
      <div className={style.rankGreen}>
        <p>
          {rank} <i className="fas fa-caret-up"></i>
        </p>
      </div>
    </div>
  );
};

const RankRedLine = ({ title, rank }) => {
  return (
    <div className={style.lineTicket}>
      <p>{title}</p>
      <div className={style.rankRed}>
        <p>
          {rank} <i className="fas fa-caret-down"></i>
        </p>
      </div>
    </div>
  );
};

const RankLineText = ({ title, rank, height = 60 }) => {
  return (
    <div className={style.lineTicket} style={{ height }}>
      <p>{title}</p>
      {/* <div className={style.rankRed}> */}
      <p>{rank}</p>
      {/* </div> */}
    </div>
  );
};

export { MarketViewItem, RankLineText };
