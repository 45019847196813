import React, { useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import badge1 from "../../../assets/images/StrategicGame/Component25e311.svg";
import badge2 from "../../../assets/images/StrategicGame/Component3581.svg";
import img1 from "../../../assets/images/StrategicGame/Component51–12.svg";
import badge3 from "../../../assets/images/StrategicGame/Componente3551.svg";
import img2 from "../../../assets/images/StrategicGame/Group 4406.svg";
import img3 from "../../../assets/images/StrategicGame/Group 4410.svg";
import badg44 from "../../../assets/images/StrategicGame/Mask Group 14.svg";
import img4 from "../../../assets/images/StrategicGame/Mask-Group114.svg";
import MaskGroup144 from "../../../assets/images/StrategicGame/MaskGroup14.svg";
import runningSolid from "../../../assets/images/StrategicGame/running-solid.svg";
import { avatars, countries, getLogoById } from "../../../helpers/centerData";
import { getscore, getScoreGlobal } from "../../../redux/actions";
import { RankingStatsModal } from "../../../components/modal/FinEdgeModals/RankingStatsModal/RankingStatsModal";

const ListClassItem = ({ t, active = false, onClick, index, item = {} }) => {
  return (
    <div
      className={`list-item-classment ${active ? "active-classment" : ""}`}
      onClick={() => onClick(index)}
      style={{cursor: "pointer"}}
    >
      <div className="list-item-classment-b1">
        <h3>{index + 1}</h3>
        <img
          src={
            "https://www.nowedge.io/now-edge-api/avatars/User23/56959-profile.jpg"
          }
          style={{
            backgroundColor: "aliceblue",
            borderRadius: " 50%",
            width: `34px`,
          }}
        />
        <div>{"Kelly Ford"}</div>
      </div>
      <div className="list-item-ranking">
        <h3>{t("finEdge.classement.valorisation")} </h3>
        <h4>{123}</h4>
      </div>
      <div className="list-item-ranking">
        <h3>{t("finEdge.classement.performance")} </h3>
        <h4>30%</h4>
      </div>
      <div className="list-item-ranking">
        <h3>{t("finEdge.classement.progression")} </h3>

        <h4 style={{ color: "#10C369" }}>+2</h4>
      </div>
      <div className="list-item-ranking">
        <h3>{t("finEdge.classement.ratioSharpe")} </h3>
        <h4>0,7</h4>
      </div>
    </div>
  );
};

const ListClass = ({ t, scoreGlobal = [], setShowClassement }) => {
  const [indexActive, setindexActive] = useState(null);

  let list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 12];

  const clickItem = (index) => {
    setShowClassement(true)
  };
  return (
    <div className="list-classment">
      {list.map((item, index) => {
        return (
          <ListClassItem
            t={t}
            key={index}
            index={index + 3}
            active={index === indexActive}
            onClick={clickItem}
            item={item}
          />
        );
      })}
    </div>
  );
};

const DetailsClassment = ({
  t,
  scoreGlobal,
  centerId,
  user1 = true,
  user2 = true,
  user3 = true,
  setShowClassement,
}) => {
  return (
    <div className="details-classment">
      <div style={{ position: "relative" }}>
        {user2 && (
          <>
            <div
              className="details-class-2"
              style={{
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                alignItems: "center",
                height: "140px",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowClassement(true);
              }}
            >
              <img
                src={
                  "https://www.nowedge.io/now-edge-api/avatars/User23/56959-profile.jpg"
                }
                style={{ width: "56px", height: "56px", borderRadius: 28 }}
              />
              <img
                src={badge2}
                style={{
                  position: "absolute",
                  right: "45px",
                  top: "44px",
                  width: "29px",
                  height: "29px",
                }}
              />
              <p>{"Chris Foster"}</p>
              <div>
                <div className="text-score">
                  {t("finEdge.classement.valorisation")}
                </div>
              </div>
              <div>
                <div className="text-score-n">{"23843€"}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ position: "relative" }}>
        {user1 && (
          <>
            <div
              className="details-class-1"
              style={{ height: "161px", cursor: "pointer" }}
              onClick={() => {
                setShowClassement(true);
              }}
            >
              <img
                src={
                  "https://www.nowedge.io/now-edge-api/avatars/User23/56959-profile.jpg"
                }
                style={{
                  width: "66px",
                  height: "66px",
                  alignSelf: "center",
                  borderRadius: 33,
                }}
              />
              <img
                src={badge1}
                style={{
                  position: "absolute",
                  right: "42px",
                  top: "59px",
                  width: "29px",
                  height: "29px",
                }}
              />
              <p>{"Chris Foster"}</p>
              <div>
                <div className="text-score">
                  {t("finEdge.classement.valorisation")}
                </div>
              </div>
              <div>
                <div className="text-score-n">{"23843€"}</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ position: "relative" }}>
        {user3 && (
          <>
            <div
              className="details-class-3"
              style={{ height: "140px", cursor: "pointer" }}
              onClick={() => {
                setShowClassement(true);
              }}
            >
              <img
                src={
                  "https://www.nowedge.io/now-edge-api/avatars/User23/56959-profile.jpg"
                }
                style={{
                  width: "56px",
                  height: "56px",
                  alignSelf: "center",
                  borderRadius: 28,
                }}
              />
              <img
                src={badge3}
                style={{
                  position: "absolute",
                  right: "45px",
                  top: "44px",
                  width: "29px",
                  height: "29px",
                }}
              />
              <p style={{ marginTop: "20px" }}>{"Chris Foster"}</p>
              <div>
                <div className="text-score">
                  {t("finEdge.classement.valorisation")}
                </div>
              </div>
              <div>
                <div className="text-score-n">{"23843€"}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Ranking = () => {
  const history = useHistory();

  const [showClassement, setShowClassement] = useState(false);
  const { t } = useTranslation();

  const CLASSEMENT = 1;

  return (
    <div className="ranking-container">
      {showClassement && (
        <RankingStatsModal
          show={showClassement}
          onHide={() => {
            setShowClassement(false);
          }}
        />
      )}

      <div
        className="classement-content"
        style={{ paddingRight: 0, marginTop: 90 }}
      >
        <div className="classement-content-block">
          <div className="heder" style={{ marginBottom: 20, width: "34rem" }}>
            <h3>{t(`finEdge.classement.title`)}</h3>
            <h5>
              {" "}
              {t(`finEdge.classement.subTitle`)} {CLASSEMENT}
              {CLASSEMENT === 1
                ? t(`finEdge.classement.first`)
                : t(`finEdge.classement.second`)}
            </h5>
          </div>
          <DetailsClassment
            t={t}
            setShowClassement={setShowClassement}
            // user1={user1}
            // user2={user2}
            // user3={user3}
          />
          <ListClass t={t} setShowClassement={setShowClassement} />
        </div>
      </div>
    </div>
  );
};

export { Ranking };
