

import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

function DernierCours({}) {
    return (
        <div className="card-box">
            <h4 className="header-title m-0">Dernier cours</h4>
            <p className={["text-muted  m-0",style.s_title].join(' ')} style={{fontSize:'14px'}} >16/02/2021 22:34:22 </p>

            <div className={style.content_lt}>
                <div className="widget-detail-1 text">
                    <h2 className={["font-weight-normal p-0 m-0",style.prix].join(" ")}> 212,21€ </h2>
                </div>
                <div>
                <span className="badge badge-success badge-pill">32% <i className="ml-2 fas fa-caret-up"></i> </span>
                </div>

            </div>
            <p className="text-muted  text-right" style={{fontSize:'9px'}}>
                    Variation par rapport a la veille 
            </p>

            <button type="button" className={["btn btn-block btn--md ",style.btn_ach].join(" ")}>Acheter</button>
            <button type="button" className={["btn btn-block btn--md ",style.btn_vant].join(" ")}>Vendre</button>
    
</div>
    )
}

DernierCours.propTypes = {

}

export default DernierCours

